import Cookies from 'js-cookie';

export * from './file';
export * from './string';
export * from './groupObjects';

export const getToken = () => {
  let token = '';
  const a = Cookies.get();
  if (a?.loginObj) {
    const loginObject = JSON.parse(a.loginObj);
    token = loginObject?.data?.auth_token || '';
  }
  return token;
};